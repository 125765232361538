import { Col, Row } from "antd";
import NavBarSection from "../../shared/components/nav-bar-section/nav-bar-section.ui";
import DownloadAppSection from "../home/partials/download-app-section.ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


function DownloadAppUi() {
    const { i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage("ar");
    }, [i18n]);
    return (
        <Row>
            <Col span={24}>
                <NavBarSection isWhite={false} />
            </Col>
            <Col span={24}>
                <DownloadAppSection asPage={true} />
            </Col>
        </Row>
    );
}
export default DownloadAppUi;