import { useTranslation } from "react-i18next";
import AppSnapshot2 from "../../../assets/imgs/download-app-section-ar.png";
import AppSnapshot1 from "../../../assets/imgs/download-app-section.png";
import DownloadAppleImg from "../../../assets/imgs/download-apple.png";
import DownloadGoogleImg from "../../../assets/imgs/download-google.png";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";

const DownloadAppSection = ({ asPage = false }) => {
    const { t, i18n } = useTranslation();
    const downloadLinks = [
        { image: DownloadGoogleImg, alt: "downloadGoogle", url: "https://play.google.com/store/apps/details?id=com.app.thrivve.finance.thrivve" },
        { image: DownloadAppleImg, alt: "downloadApple", url: "https://apps.apple.com/us/app/thrivve-app/id6478132223" },
    ];

    return (
        <section className={`download-app-section ${asPage ? "asPage" : ""}`}>
            <div className="screen-wrapper">
                <div className="text-wrapper">
                    <FadeUpFramer>
                        <div className="download-app-text-content">
                            <h3>{t("downloadSectionTitle")}</h3>
                            <p>{t("downloadSectionDescription")}</p>
                        </div>
                        <div className="button-wrapper">
                            {downloadLinks.map((link, index) => (
                                <a key={`download-link-${index}`} href={link.url} target="_blank" rel="noreferrer">
                                    <img src={link.image} alt={t(link.alt)} />
                                </a>
                            ))}
                        </div>
                    </FadeUpFramer>
                </div>
                <div className="picture-wrapper">
                    <FadeUpFramer>
                        <img src={i18n.dir() === "ltr" ? AppSnapshot1 : AppSnapshot2} alt={t("snapshotMobileApp")} />
                    </FadeUpFramer>
                </div>
            </div>
        </section>
    );
};

export default DownloadAppSection;
