import DrivingImg from "../../../assets/imgs/driving.jpg";
import { useTranslation } from "react-i18next";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";
import { useState } from "react";
import { Link } from "react-router-dom";

const UnlockPotentialSection = () => {
    const [showHeroImgs, setShowHeroImgs] = useState(false);
    const { t } = useTranslation();

    const getStartedUrl = process.env.REACT_APP_UBER_LEAD_SITE_URL ?? "https:///uber.thrivve.me";

    return (
        <section className="unlock-potential-section">
            <div className="floating-img-wrapper">
                <img
                    style={{ display: "none" }}
                    src={DrivingImg}
                    alt="Man In Car"
                    onLoad={() => setShowHeroImgs(true)}
                />
                {showHeroImgs ? (
                    <FadeUpFramer className="hero-animation-wrapper">
                        <div
                            className="hero-img"
                            style={{
                                background: `url(${DrivingImg})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPositionX: "right",
                            }}
                        ></div>
                    </FadeUpFramer>
                ) : (
                    <></>
                )}
            </div>
            <div className="screen-wrapper">
                <div className="title-wrapper">
                    <FadeUpFramer>
                        <p className="subtitle">{t("unlockYourPotential")}</p>
                        <h3 className="title">{t("driveEarnOwn")}</h3>
                        <p className="text">{t("driveEarnOwnDetails")}</p>
                        <Link className="hide-in-mobile" to={getStartedUrl}>
                            <button className="get-started-btn hide-in-mobile">{t("getStarted")}</button>
                        </Link>
                    </FadeUpFramer>
                </div>
                <div className="hero-spacer"></div>
            </div>
        </section>
    );
};

export default UnlockPotentialSection;